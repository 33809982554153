import * as i from 'types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import { Input } from 'common/form';
import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';
import { PageTransition } from 'common/layout/PageTransition';
import { StepButtonGroup } from 'modules/StepButtonGroup';
import { validation } from 'services';

import {
  HeaderContainer,
  ButtonContainer,
  StyledForm,
  FormContainer,
} from './styled';

export const UserInfoStep: React.FC<UserInfoStepProps> = ({
  redirectUrl, currentStep, nextStep, userInfoData, setUserInfoData,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
  });

  const defaultValues = {
    firstName: userInfoData.firstName || '',
    lastName: userInfoData.lastName || '',
  };

  const handleNextStep = (data: i.UserRegisterFormData) => {
    const newData = { ...userInfoData, ...data };

    setUserInfoData(newData);
    nextStep();
  };

  return (
    <PageTransition>
      <StyledForm onSubmit={handleSubmit(handleNextStep)}>
        <HeaderContainer>
          <Heading as="h2" variant={['gray', 'dark']}>{t('Hi there, how can we call you?')}</Heading>
        </HeaderContainer>
        <FormContainer>
          <Input
            {...register('firstName', { ...validation.required })}
            label={t('first name')}
            defaultValue={defaultValues?.firstName}
            error={errors.firstName}
          />
          <Input
            {...register('lastName', { ...validation.required })}
            label={t('last name')}
            defaultValue={defaultValues?.lastName}
            error={errors.lastName}
          />
          <Text variant={['gray', 'dark']}>
            {t('already have an account?')} &nbsp;
            <Link
              to={'/login'}
              state={{ redirectUrl }}
            >
              {t('login')}
            </Link>
          </Text>
        </FormContainer>
        <ButtonContainer>
          <StepButtonGroup {...{ currentStep }} />
          <Button
            type="submit"
            size="fullWidth"
            disabled={!isValid}
          >
            {t('next')}
          </Button>
        </ButtonContainer>
      </StyledForm>
    </PageTransition>
  );
};

type UserInfoStepProps = {
  currentStep: 1 | 2;
  userInfoData: i.UserBase;
  nextStep: () => void;
  setUserInfoData: (newData: i.User) => void;
  redirectUrl: string;
};
