import * as React from 'react';
import { graphql } from 'gatsby';

import { Template, Split, ImageBlock } from 'common/layout';
import { RegisterForm } from 'modules/RegisterForm';
import RegistrationHeaderImage from 'images/register.jpg';

const Register: React.FC<RegisterPageProps> = ({ location }) => (
  <Template>
    <Split.Container $backgroundColor>
      <Split.Content $first $hideOnMobile>
        <ImageBlock
          fullHeight
          logoInclude
          alt="PLTS registration flow"
          src={RegistrationHeaderImage}
        />
      </Split.Content>
      <Split.Content>
        <RegisterForm location={location} />
      </Split.Content>
    </Split.Container>
  </Template>
);

export default Register;

export const query = graphql`
  query Register($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;

type RegisterPageProps = {
  location: {
    state: {
      redirectUrl: string;
    };
  }
};
